// Copyright © 2022 Move Closer

import { AnyObject, EventPayload } from '@movecloser/front-core'

import {
  ImageData,
  Module,
  ModuleContent,
  Related
} from '../../contexts'

import { AvailableModuleDriver } from '../allowed'

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
export type VideoModule = Module<VideoModuleContent, AvailableModuleDriver.Video, VideoVersion>

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
export interface VideoModuleData {
  /**
   * Determine if autoplay YT video.
   */
  autoplay?: boolean

  /**
   * Additional description of the embedded content.
   *
   * @version EmbedVersion.YouTube
   */
  description?: string

  /**
   * Video thumbnail.
   *
   * @version EmbedVersion.YouTube
   */
  thumbnail?: ImageData

  /**
   * ID of the YouTube video.
   *
   * @version EmbedVersion.YouTube
   */
  videoId?: string
}
/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
export type VideoModuleContent = VideoModuleData & ModuleContent

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
export interface VideoModuleContentInput extends Omit<VideoModuleContent, 'thumbnail'>, ModuleContent {
  /**
   * Unresolved thumbnail
   */
  thumbnail: Related | null
}

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
export enum VideoVersion {
  YouTube = 'youtube'
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 * @author Google
 *
 * @see https://developers.google.com/youtube/iframe_api_reference#Events
 */
export enum PlayerState {
  Buffering = 3,
  Ended = 0,
  Paused = 2,
  Playing = 1,
  Unstarted = -1,
  VideoCued = 5
}

/**
 * Event payload of the `ui:yt-player.state-changed` event.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export type UiYTPlayerStateChangedEventPayload = EventPayload<{
  /**
   * The YT player instance that fired (emitted) the event.
   */
  player: AnyObject

  /**
   * Current state of the given player.
   */
  state: PlayerState
}>
